import { interpret } from 'xstate'
import { useCallback, useEffect, useState } from 'react'

import { AppMachine as AppManager } from 'midi-city-app-manager'
import { assertIsString } from 'assertate'
import Router from 'next/router'

// DO NOT CHECK IN UNCOMMENTED
// import { inspect } from '@xstate/inspect'
const devTools = typeof window !== 'undefined' && false

// if (devTools) {
//   inspect({
//     // options
//     // url: 'https://statecharts.io/inspect', // (default),
//     iframe: false
//   })
// }

export default function useAppMachine(): AppManager.Interpreter {
  const [interpreter] = useState(() => {
    // @ts-expect-error
    const interpreter = interpret(AppManager.machine, { devTools })
    interpreter.start()
    return interpreter as AppManager.Interpreter
  })

  useEffect(() => {
    interpreter.onTransition(state => {
      const { event } = state
      if (event.type !== 'PAGE_CHANGE') {
        return
      }

      const pathName = event.page

      // if (pathName === event.page) {
      //   return
      // }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Router.push(pathName, undefined, { shallow: true })
    })
  }, [interpreter])

  const sendExternalRouteChange = useCallback(() => {
    interpreter.send({
      type: 'PAGE_CHANGE_EXTERNAL',
      page: Router.pathname as AppManager.Page
    })
  }, [interpreter])

  useEffect(() => {
    const urlBase = process.env.URL_BASE
    const recordingAvailable = process.env.FEATURE_RECORDING === 'true'

    assertIsString(urlBase)

    if (interpreter.state.matches('uninitialized')) {
      interpreter.send({
        type: 'INITIALIZE',
        urlBase,
        recordingAvailable
      })

      sendExternalRouteChange()

      if (typeof globalThis !== 'undefined') {
        // allowing easy debugging
        // @ts-expect-error
        globalThis.__midi_city_interpreter = interpreter
      }
      Router.events.on('routeChangeComplete', sendExternalRouteChange)
    }

    return (): void => {
      Router.events.off('routeChangeComplete', sendExternalRouteChange)
    }
  }, [interpreter, sendExternalRouteChange])

  useEffect(() => {
    interpreter.onTransition(state => {
      if (state.event.type === 'GLOBAL_FAILED') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        Router.push('/unsupported-browser')
      }
    })
  }, [interpreter])

  return interpreter
}
