import { initSentry, AppMachine } from 'midi-city-ui'
import { Context, ToneAudioNode } from 'tone'
import { captureException, Severity } from '@sentry/browser'

import '../styles/global.css'
import React from 'react'
import Head from 'next/head'

import ga from 'midi-city-app-manager/src/ga'

import { AppPropsType } from 'next/dist/next-server/lib/utils'
import Polyfills from '../components/Polyfills'
import useAppMachine from '../hooks/use-app-machine'

Context.prototype.toJSON = (): Object => {
  return {}
}

// @ts-expect-error
ToneAudioNode.prototype.toJSON = (): Object => {
  return {}
}

initSentry()

if (
  typeof window === 'undefined' &&
  process.env.NODE_ENV === 'production' &&
  process.env.GCP_PROFILING === 'true'
) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  import('@google-cloud/profiler').then(Profiler => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Profiler.start()
  })
}

if (typeof window !== 'undefined') {
  ga?.initialize()
    .then(() => {
      ga?.pageview(window.location.pathname)
    })
    .catch(e => {
      captureException(e, { level: Severity.Info })
    })
}

function AppCustom(props: AppPropsType): JSX.Element {
  const { Component, pageProps } = props

  const interpreter = useAppMachine()

  return (
    <>
      <Polyfills />
      <Head key="app">
        <title> midi.city - free online synth instrument </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <AppMachine appMachine={interpreter}>
        <Component appMachine={interpreter} {...pageProps} />
      </AppMachine>
    </>
  )
}

export default AppCustom
