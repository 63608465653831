import { useEffect } from 'react'
import { from } from 'rxjs'
import { retryBackoff } from 'backoff-rxjs'
import { tap } from 'rxjs/operators'

const retry = retryBackoff({
  initialInterval: 100,
  maxRetries: 4
})

export default function Polyfills(): null {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.ResizeObserver === undefined) {
      from(import('@juggle/resize-observer')).pipe(
        retry,
        tap(module => {
          // @ts-expect-error
          window.ResizeObserver = module.default
        })
      )
    }

    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      from(import('smoothscroll-polyfill')).pipe(
        retry,
        tap(module => {
          module.default.polyfill()
        })
      )

      // @ts-expect-error
      from(import('pepjs/dist/pep.js')).pipe(
        retry,
        tap(() => {
          // eslint-disable-next-line no-console
          console.log('Loaded PEP.js')
        })
      )
    }
  }, [])

  return null
}
